import useFetchWitPagination from "../hooks/useFetchWithPagination";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import useFetchSummary from "../hooks/useFetchSummary";
import { yupResolver } from "@hookform/resolvers/yup";
import serializeDate from "src/utils/serialize-date";
import { DataList, FilterCard } from "./components";
import useLocales from "src/hooks/useLocales";
import { PATH_USER } from "src/routes/paths";
import Summary from "./components/summary";
import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import Page from "src/components/Page";
import { object, string } from "yup";
import moment from "moment";

const defaultValues = {
	start_date: "",
	end_date: "",
	user_id: "",
	payment_type: "all",
	// rows_page: "10",
};

const schema = object().shape({
    start_date: string()
      .test("is-valid", "errors.date.valid_date.test", (v) => {
        if (v === null) return true;
        return moment(v, "YYYY/MM/DD").isValid();
      })
      .test("is-valid", "errors.date.valid_start.test", (v, ctx) => {
        if (v === null) return true;
        if (ctx.parent.end_date === null) return true;
        return (moment(ctx.parent.end_date, "YYYY/MM/DD").diff(moment(v, "YYYY/MM/DD")) >= 0);
      })
      .transform((v) => serializeDate(v))
      .nullable(),
    end_date: string()
      .test("is-valid", "errors.date.valid_date.test", (v) => {
        if (v === null) return true;
        return moment(v, "YYYY/MM/DD").isValid();
      })
      .test("is-valid", "errors.date.valid_end.test", (v, ctx) => {
        if (v === null) return true;
        if (ctx.parent.start_date === null) return true;
        return (moment(ctx.parent.start_date, "YYYY/MM/DD").diff(moment(v, "YYYY/MM/DD")) <= 0);
      })
      .transform((v) => serializeDate(v))
      .nullable(),
});

const Index = () => {
  const { translate } = useLocales();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const filter = methods.watch();

  const { data, fetchData, ...rest } = useFetchWitPagination("ewallet", filter);

  const summary = useFetchSummary("ewallet-data");

  return (
    <div>
      <Page title={translate("userFinancial.eWallet.ewalletTitile")}>
        <Box sx={{ p: 2 }}>
          <HeaderBreadcrumbs
            heading={translate("userFinancial.eWallet.myEwallet")}
            links={[
              { name: translate("dashboard"), href: PATH_USER.root },
              { name: translate("userFinancial.eWallet.myEwallet") },
            ]}
          />

          <Grid container spacing={3}>
            <Summary summary={summary} />
            <Grid item xs={12} md={12}>
              <FilterCard
                methods={methods}
                fetchData={(filter) => {
                  fetchData(1, filter);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <DataList data={data} {...rest} />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </div>
  );
};

export default Index;
