import DatePicker from "@mui/lab/DatePicker";
import useLocales from "src/hooks/useLocales";
import AmountType from "./components/amountType";
import { Controller, useForm } from "react-hook-form";
import { FormProvider } from "src/components/hook-form";
import UsersSearch from "src/components/autoComplete/users";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
// import RowsPerPage from "./components/rowsPerPage";
// import UserAutoComplete from "src/components/UsersAutoComplete";

const FilterCard = ({ fetchData, methods }) => {
	const { translate } = useLocales();

	// const methods = useForm({
	// 	defaultValues,
	// });

    const onSubmit = async (inputData) => await fetchData(inputData);

	// const onSubmit = (inputData) => {
	// 	const { start_date, end_date, ...rest } = inputData;
	// 	fetchData({
	// 		...rest,
	// 		start_date: start_date ? new Date(start_date).toLocaleDateString("en-GB") : "",
	// 		end_date: end_date ? new Date(end_date).toLocaleDateString("en-GB") : "",
	// 	});
	// };

	const { control } = methods;

	return (
		<div>
			<Card sx={{ p: 3 }}>
				<Box>
					<Typography variant="subtitle2">{translate("userFinancial.eWallet.walletHistory")}</Typography>
					<FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
						<Box
							sx={{
								display: "grid",
								rowGap: 3,
								columnGap: 2,
								marginTop: 3,
								gridTemplateColumns: {
									xs: "repeat(1, 1fr)",
									sm: "repeat(5, 1fr)",
								},
							}}
						>
							<Controller
								control={control}
								name="start_date"
								render={({ field, fieldState: { error } }) => (
									<DatePicker
										label="Pick Start Date"
										inputFormat="dd/MM/yyyy"
										value={field.value}
										onChange={(newValue) => {
											field.onChange(newValue);
										}}
										renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} size="small" />}
									/>
								)}
							/>
							<Controller
								control={control}
								name="end_date"
								render={({ field, fieldState: { error } }) => (
									<DatePicker
										inputFormat="dd/MM/yyyy"
										label={translate("userFinancial.eWallet.pickEndDate")}
										value={field.value}
										onChange={(newValue) => {
											field.onChange(newValue);
										}}
										renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} size="small" />}
									/>
								)}
							/>

							{/*
                                <UserAutoComplete freeSolo={false} multiple={false} onChange={(_, v) => { setValue("user_name", v.id); }} />
                            */}

							<UsersSearch name="user_id" props={{ size: "small" }} />

							<AmountType />

							{/* <RowsPerPage /> */}

                            <Button type="submit" variant="contained" name="get-report">
								{translate("userFinancial.eWallet.getReport")}
							</Button>
						</Box>
						{/* <Box mt={2} sx={{ float: "right" }}> </Box> */}
					</FormProvider>
				</Box>
			</Card>
		</div>
	);
};

export default FilterCard;
