import { useEffect, useState } from "react";
import useErrors from "src/hooks/useErrors";
import axiosInstance from "src/utils/fetchUser";
import serializeDate from "src/utils/serialize-date";
import usePagination from "src/components/pagination/usePagination";

const useFetchWitPagination = (url, filter = {}) => {
  const [data, setData] = useState([]);
  const handleErrors = useErrors();
  const { count, onChange, page, rowStart, seed } = usePagination();

  const fetchData = async (page = 1, filter = {}) => {
    try {
      const { data, status } = await (
        await axiosInstance.get(url, {
          params: {
            page,
            ...filter,
          },
        })
      ).data;
      if (status) {
        const { data: list, from, last_page } = data;
        seed(last_page, from);
        onChange(null, page);
        setData(list);
      } else {
        seed(1, 1);
        setData([]);
      }
    } catch (err) {
      console.log(err);
      handleErrors(err);
    }
  };

  useEffect(() => {
    const { start_date, end_date, ...rest } = filter;

    fetchData(page, {
        start_date: serializeDate(start_date),
        end_date: serializeDate(end_date),
        ...rest,
    });
  }, [page]);

  return { data, count, onChange, page, rowStart, fetchData };
};

export default useFetchWitPagination;
