import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoginForm } from "src/sections/auth/login";
import Message from "./Message";
import NoAccountSection from "./NoAccountSection";
import useResponsive from "src/hooks/useResponsive";
import LoginBanner from "src/images/login.webp";
import Image from "src/components/Image";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const MainSection = () => {
  const mdUp = useResponsive("up", "md");
  return (
    <Container maxWidth="sm">
      <ContentStyle>
        {!mdUp && (
          <Image
            visibleByDefault
            disabledEffect
            alt="login"
            src={LoginBanner}
          />
        )}
        <Message />

        <LoginForm />

        {/* <NoAccountSection /> */}
      </ContentStyle>
    </Container>
  );
};

export default MainSection;
