import { useSnackbar } from "notistack";
import axiosInstance from "src/utils/fetchUser";

function useCreditOnce(cb) {
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async () => {
    try {
      const { data, status } = await axiosInstance("/pending-to-complete", {
        params: {
          complete_status: 1,
        },
      });
      if (status === 200) {
        enqueueSnackbar(data.message);
        await cb();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };
  return { onSubmit };
}

export default useCreditOnce;
