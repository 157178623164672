// import { capitalCase } from "change-case";
import React from "react";
import Map from "src/components/map";
import useLocales from "src/hooks/useLocales";
import { isBinary } from "src/utils/isBinary";
import { RHFSelect } from "src/components/hook-form";

const AmountType = () => {
  const { translate } = useLocales();

  return (
    <RHFSelect
      name="payment_type"
      label={translate("userFinancial.eWallet.amountType")}
      size="small"
    >
      <Map
        list={isBinary() ? [...options, ...binary] : options}
        render={({ value, label }) => <Option value={value} label={label} />}
      />
    </RHFSelect>
  );
};

const Option = ({ value, label }) => {
  const { translate } = useLocales();

  return <option value={value}>{translate(label)}</option>;
};

const options = [
  { value: "all", label: "userFinancial.eWallet.all" },
  { value: "lifestyle_bonus_monthly", label: "lifeStyleBonusMonthly" },
  { value: "global_leadership_pool_bonus", label: "globalLeadershipPoolbonus" },
  { value: "fast_start_bonus", label: "fastStartBonus" },
  { value: "dualteam_bonus", label: "dualTeamBonus" },
  { value: "matching_bonus", label: "matchingBonus" },
  { value: "rank_bonus", label: "rankBonus" },

  { value: "referral_bonus", label: "userFinancial.eWallet.referral_bonus" },
  { value: "achievement_bonus", label: "userFinancial.eWallet.achievement_bonus" },
  // { value: "first_order_bonus", label: "userFinancial.eWallet.first_order_bonus" },

  { value: "fund_transfer", label: "userFinancial.eWallet.fund_transfer" },
  { value: "credited_by_admin", label: "userFinancial.eWallet.credited_by_admin" },
  { value: "deducted_by_admin", label: "userFinancial.eWallet.deducted_by_admin" },

  // { value: "level_commission", label: "Level Commission" },
  // { value: "released", label: "userFinancial.eWallet.released" },
  // { value: "pending", label: "userFinancial.eWallet.pending" },
  // { value: "failed", label: "userFinancial.eWallet.failed" },
  // { value: "rejected", label: "userFinancial.eWallet.rejected" },
  // { value: "finished", label: "userFinancial.eWallet.finished" },
  // { value: "approved", label: "userFinancial.eWallet.approved" },
  // { value: "self_transfer", label: "userFinancial.eWallet.self_transfer" },
  // { value: "fund_transfer", label: "userFinancial.eWallet.fund_transfer" },
  // { value: "plan_purchase", label: "userFinancial.eWallet.plan_purchase" },
];

const binary = [
  // { value: "binary_bonus", label: "userFinancial.eWallet.binary_bonus" },
];

export default AmountType;
